import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router";
import Header from "./components/Header";
import Login from "./pages/Login";
// import Main from "./pages/Main";
import HotkeysMain from "./pages/HotkeysMain";
// import Landing from "./pages/Landing";
import { connect } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import { Logout } from "./pages/Logout";
export const App = () => {
	return (
		<div className={`App`}>
			<Router>
				<Header />
				<div className='main-content'>
					<Switch>
						{/* <Route path='/' exact component={Landing} /> */}
						<Route path='/' exact component={Login} />
						<Route path='/login' exact component={Login} />
						<Route path='/logout' exact component={Logout} />
						{/* <ProtectedRoute path='/dashboard' exact component={Main} /> */}
						<ProtectedRoute path='/dashboard' exact component={HotkeysMain} />
						<Route path='*' component={() => "404 Page not found"} />
					</Switch>
				</div>
			</Router>
		</div>
	);
};

const mapStateToProps = (state) => {
	// let { app } = state;
	// return { theme:app.theme };
	return {};
};

export default connect(mapStateToProps, null)(App);
