import React from "react";
import { connect } from "react-redux";
// import { useHotkeys } from "react-hotkeys-hook";
import Main from "./Main";
// import { toggleHelp } from "../redux/actions/app";

export const HotkeysMain = ({ dispatch }) => {
	// // shortcuts here
	// useHotkeys("h", (event) => {
	// 	event.preventDefault();
	// 	console.log(event.key);
	// 	dispatch(toggleHelp());
	// 	return false;
	// });
	return <Main />;
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(HotkeysMain);
