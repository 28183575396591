export const default_state = {
	user: {
		login: false,
		userId: "",
		responseCode: 404,
		status: "",
		customerCode: "",
		firstName: "",
		lastName: "",
		role: "",
		logo: "",
		authToken: "",
	},
	app: {
		theme: "light",
		// bulb: true, //shifted to filter
		error: null,
		help: false,
		validationError: null,
		default_brand_shape: { brandName: "", facingCount: "", promotionFacingCount: "", oosFacingCount: "", regularPrice: "", posPrice: "", multipackPrice: "" },
	},
	filter: {},
};
