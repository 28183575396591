import { combineReducers } from "redux";
import user from "./user";
import app from "./app";
import filter from "./filter";

export default combineReducers({
	user,
	app,
	filter,
});
