import React, { useState } from "react";
import { connect } from "react-redux";
import { resetUser } from "./../redux/actions/user";
import { Redirect } from "react-router";
export let Logout = ({ dispatch }) => {
	// reset state.user and redirect to /login
	const [content, setContent] = useState(
		<div className='container md-5 p-5'>
			<h5 className='w-100 text-center'>Loggin out.....</h5>
		</div>
	);

	setTimeout(() => {
		dispatch(resetUser());
		setContent(<Redirect to='/login' />);
	}, 2000);

	return <div>{content}</div>;
};

const mapStateToProps = (state) => {
	return {};
};

Logout = connect(mapStateToProps, null)(Logout);

export default Logout;
