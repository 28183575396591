import React from "react";
import "./Detail.scss";
import { FaStore as StoreIcon } from "react-icons/fa";
import {FiGlobe as EarthIcon,FiUser as UserIcon } from "react-icons/fi";

export const StoreDetail = ({ data }) => {
	let { storeId, street, city, state,mappedUser,mappedTerritory } = data;
	return (
		<div className='card detail-card mb-3'>
			<div className='card-header py-2'>
				<span className='font-weight-bold h5 center justify-content-start'>
					{" "}
					<StoreIcon /> &nbsp; Store Details
				</span>
			</div>
			<div className='card-body pt-3'>
				<span className='font-weight-bold h5'>{storeId}</span>
				<br />
				<span className='h6 text-muted'>
					{street}, {city}, {state}
				</span>
				<br />
				<br />
				<span className='h6 text-muted'>
					{" "}
					<UserIcon /> {mappedUser===undefined ? "Not Available" : mappedUser}
				</span>
				<br />
				<span className='h6 text-muted'>
					{" "}
					<EarthIcon /> {mappedTerritory===undefined ? "Not Available" : mappedTerritory}
				</span>
			</div>
		</div>
	);
};

export default StoreDetail;
