import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { authenticateUser } from "./../../logic/network/auth";
import { Redirect } from "react-router";
import { updateUser } from "./../../redux/actions/user";
import logo from "./../../assets/img/logo.png";
import "./LoginForm.scss";

import Swal from "sweetalert2";
export const LoginForm = ({ dispatch }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [authenticated, setAuthenticated] = useState(false);
	const [message, setMessage] = useState("");
	const [processing, setProcessing] = useState(false);
	const formSubmit = (e) => {
		e.preventDefault();
		setProcessing(true);
		authenticateUser({ username, password }).then((response) => {
			let { status, message, state } = response;
			let ok = status === "200";
			setAuthenticated(ok);
			setMessage(response.message);
			if (ok && state) {
				dispatch(updateUser(state));
			} else {
				Swal.fire({
					title: "Login Failed",
					text: message,
					icon: "error",
					showCancelButton: true,
				});
			}
			setProcessing(false);
			return;
		});
	};

	if (authenticated) {
		return <Redirect to='dashboard' />;
	}

	return (
		<Fragment>
			<form>
				<div className='form-group text-center'>
					<img src={logo} className='d-inline-block align-top logo' alt='Snap2Insight logo' />
				</div>
				<div className='form-group'>{message ? <span className='message'>{message}</span> : ""}</div>
				<div className='form-group'>
					<label htmlFor='user-id'>User ID</label>
					<input type='text' className='form-control' id='user-id' placeholder='User ID' value={username} onChange={(e) => setUsername(e.target.value)} />
				</div>
				<div className='form-group'>
					<label htmlFor='password'>Password</label>
					<input type='password' className='form-control' id='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
				</div>
				<button type='submit' className='btn btn-primary' disabled={processing} onClick={formSubmit}>
					{processing ? "Logging in...." : "Login"}
				</button>
				<br />
				<br />
			</form>
			<span className='copyright text-center w-100 d-block'>Copyright © 2020 -Snap2Insight Inc, Portland, Oregon USA</span>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(LoginForm);
