import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "./../assets/img/logo.png";
import "./Header.scss";
// import { MdSettings as SettingsIcon } from "react-icons/md";
// import { IoMdHelp as HelpIcon } from "react-icons/io";
import { RiLogoutCircleRLine as LogOutIcon } from "react-icons/ri";
import { RiLoginCircleLine as LogInIcon } from "react-icons/ri";

// import { FaSun as LightModeIcon, FaMoon as DarkModeIcon } from "react-icons/fa";
// import { toggleTheme, toggleHelp } from "../redux/actions/app";
const Header = ({ userId, firstName, lastName, userStatus, theme, dispatch }) => {
	let authenticated = userStatus === "success";
	const [content, setContent] = useState();
	useEffect(() => {
		if (authenticated) {
			setContent(
				<Link className='btn btn-sm m-1' to='/logout' data-help='Logout' help-left=''>
					<LogOutIcon size={16} />
				</Link>
			);
		} else {
			setContent(
				<Link className='btn btn-sm m-1' to='/login' data-help='Login' help-left=''>
					<LogInIcon size={16} />
				</Link>
			);
		}
	}, [authenticated]);

	/* OLD NAVBAR */
	return (
		<nav className={`navbar ${theme === "dark" ? "navbar-dark bg-dark" : "navbar-light bg-light"}`}>
			<Link className='navbar-brand' to='/'>
				<img src={logo} className='d-inline-block align-top' alt='Snap2Insight logo' />
			</Link>
			<ul className='navbar-nav'>
				{authenticated && (
					<li className='nav-item center'>
						<span className='mx-1 no-highlight'>
							{firstName} {lastName}
						</span>
					</li>
				)}
				{content}
				{/* <li className='nav-item'>
					<button className='btn mx-1 no-highlight' onClick={(_) => dispatch(toggleHelp())}>
						<HelpIcon />
					</button>
				</li>
				<li className='nav-item'>
					<button className='btn mx-1 no-highlight' onClick={(_) => dispatch(toggleTheme())}>
						{theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
					</button>
				</li> */}
			</ul>
		</nav>
	);
};
const mapStateToProps = (state) => {
	let { user, app } = state;
	return { userStatus: user.status, userId: user.userId, firstName: user.firstName, lastName: user.lastName, theme: app.theme };
};

export default connect(mapStateToProps, null)(Header);
