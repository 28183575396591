import TimeAgo from "javascript-time-ago";
import React, { useState } from "react";
import { FiGlobe as EarthIcon, FiMail as MailIcon, FiPhone as PhoneIcons, FiUser as UserIcon } from "react-icons/fi";
import Swal from "sweetalert2";
import fetchMachine from "../logic/network/fetchMachine";
import { customDayFormatter } from "../logic/util";
import "./Detail.scss";
const timeAgo = new TimeAgo("en-US");
const API_BASE = process.env.REACT_APP_API_URL;
// https://dev.snap2insight.com/api/Snap2Buy-4.0/service/S2B/login/forgotPassword
export const AgentDetail = ({ data, customerCode }) => {
	const resetUrl = `${API_BASE}/login/forgotPassword`;
	const { appVersionCode, availableDashboards, dashboardAccess, email, firstName, lastAccessedDate, lastLoginDate, lastName, mappedTerritory, mappedUser, mobileAppAccess, networkSettings, phoneNumber, role, userId, status, disabledDate } = data;
	const [processing, setProcessing] = useState(false);

	const is_disabled = String(status) !== "1";

	const disabled_date = disabledDate && new Date(disabledDate + " UTC");
	const disabled_relativeTime = disabled_date && timeAgo.format(disabled_date);
	const disabled_readableTime = disabled_date && customDayFormatter(disabled_date).readableTime;

	const login_date = lastLoginDate && new Date(lastLoginDate + " UTC");
	const login_relativeTime = login_date && timeAgo.format(login_date);
	const login_readableTime = login_date && customDayFormatter(lastLoginDate).readableTime;

	const access_date = lastAccessedDate && new Date(lastAccessedDate + " UTC");
	const access_relativeTime = access_date && timeAgo.format(access_date);
	const access_readableTime = access_date && customDayFormatter(lastAccessedDate).readableTime;

	return (
		<div className='card detail-card mb-3'>
			<div className='card-header py-2'>
				<span className='font-weight-bold h5 center justify-content-start'>
					<UserIcon /> &nbsp; Agent Details
				</span>
			</div>
			<div className='card-body pt-3'>
				<span className='font-weight-bold h5'>
					{firstName} {lastName}
				</span>
				<br />
				<span className='h6 text-muted'>
					{" "}
					<MailIcon /> {userId}
				</span>
				<br />
				<span className='h6 text-muted'>
					{" "}
					<PhoneIcons /> {phoneNumber}
				</span>
				<br />
				<span className='h6 text-muted'>
					{" "}
					<EarthIcon /> {mappedTerritory === undefined ? "Not Available" : mappedTerritory}
				</span>

				<br />
				<br />
				<span className='h6 text-muted'>
					{/* <RiDashboardFill/> */}
					Dashboard Access: <>{dashboardAccess}</>
				</span>
				<br />
				<span className='h6 text-muted'>
					{/* <RiDashboardFill/> */}
					Available Dashboards: <>{availableDashboards}</>
				</span>
				<br />
				<br />
				<span className='h6 text-muted'>
					{/* <FaMobileAlt/> */}
					Mobile App Access: <>{mobileAppAccess}</>
				</span>
				<br />
				<span className='h6 text-muted'>
					{/* <FaMobileAlt/> */}
					Mobile App Version: <>{appVersionCode || "NA"}</>
				</span>
				<br />
				<span className='h6 text-muted'>
					{/* <FaMobileAlt/> */}
					Network Preference For Uploads: <>{networkSettings || "NA"}</>
				</span>

				<br />
				<br />
				<span className='h6 text-muted'>
					Status: <span className={is_disabled ? "text-danger" : "text-success"}>{is_disabled ? "DISABLED" : "ACTIVE"}</span>
					<span>
						{" "}
						{disabled_relativeTime && `, ${disabled_relativeTime}`} {disabled_readableTime && `, ${disabled_readableTime}`}
					</span>
				</span>
				<br />
				{is_disabled && customerCode === "ITGBND" && (
					<span className='h6 text-primary'>
						Ask user to call Global Service Desk at: 888-222-7731 <br /> <br />
					</span>
				)}

				<span className='h6 text-muted'>
					Last Login:{" "}
					{login_relativeTime ? (
						<>
							{login_relativeTime} {login_readableTime && `, ${login_readableTime}`}
						</>
					) : (
						"Not Available"
					)}
				</span>
				<br />
				<span className='h6 text-muted'>
					Last active:{" "}
					{access_relativeTime ? (
						<>
							{access_relativeTime}
							{access_readableTime && `, ${access_readableTime}`}
						</>
					) : (
						"Not Available"
					)}
				</span>

				<br />
				{is_disabled ? (
					<></>
				) : (
					<span
						className='btn btn-link px-0'
						onClick={(_) => {
							setProcessing(true);
							fetchMachine
								.post(resetUrl, { userId: userId })
								.then((res) => {
									Swal.fire({
										title: res.ResultSet.row[0].responseCode === "200" ? "Success" : "Error",
										text: res.ResultSet.row[0].responseMessage,
										icon: res.ResultSet.row[0].responseCode === "200" ? "success" : "error",
										showCancelButton: false,
									});
									setProcessing(false);
								})
								.catch((e) => {
									Swal.fire({
										title: "Error",
										text: e.message,
										icon: "error",
										showCancelButton: false,
									});
									setProcessing(false);
								});
						}}
						disabled={processing}>
						{processing ? "Resetting Password..." : "Reset Password"}
					</span>
				)}
			</div>
		</div>
	);
};

export default AgentDetail;

// let dummyData = {
// 	header: "Store Details",
// 	crs: 108659,
// 	addr: "225 LAFAYETTE AVENUE, MOUNDSVILLE, West Virginia, 26041",
// 	plan_type: "FMC-Gold",
// 	ardt: "2-06-064-02",
// };
