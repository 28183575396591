import React from "react";
import { connect } from "react-redux";
import LoginForm from "../components/auth/LoginForm";
import { Redirect } from "react-router";
export const Login = ({ status }) => {
	return (
		<div className='' id='login-form-container'>
			{status === "success" ? <Redirect to='/dashboard' /> : <LoginForm />}
		</div>
	);
};

const mapStateToProps = (state) => ({
	status: state.user.status,
});

export default connect(mapStateToProps, null)(Login);
