import sha1 from "sha1";

const API_URL = process.env.REACT_APP_API_URL;

export async function authenticateUser({ username, password }) {
	// hash password
	const encryptedPassword = sha1(password);
	const userCredentials = { userId: username, password: encryptedPassword };

	const res = await fetch(API_URL + "/login", {
		method: "post",
		body: JSON.stringify(userCredentials),
		headers: {
			"Content-Type": "application/json",
		},
	});
	const data = await res.json();

	if (data.ResultSet.row[0].status === "success") {
		let user_state = { ...data.MetaInfo[0], ...data.ResultSet.row[0] };
		// update state if success,// localStorage is set in user reducer
		// store.dispatch(updateUser(user_state));
		return {
			status: data.ResultSet.row[0].responseCode,
			message: data.ResultSet.row[0].status,
			state: user_state,
		};
	} else {
		return {
			status: data.ResultSet.row[0].responseCode,
			message: data.ResultSet.row[0].status,
		};
	}
}
