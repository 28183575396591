import { cookieService } from "./cookie";

export function setUserLocalStorage(userData) {
	cookieService.set("si-token", "1234");
	window.localStorage.setItem("userInfo", JSON.stringify(userData));
	return true;
}

export const getUserLocalStorage = () => {
	const userInfo = window.localStorage.getItem("userInfo");
	if (userInfo !== null || userInfo !== "") {
		const userInfoObj = JSON.parse(userInfo);
		return userInfoObj;
	} else {
		return null;
	}
};

export const getUserId = () => {
	const userInfo = getUserLocalStorage();
	let userId = "";
	if (userInfo !== null) {
		if (userInfo.userId !== undefined && userInfo.userId !== "") {
			userId = userInfo.userId;
		}
	}
	return userId;
};

export const getAuthToken = () => {
	// check local storage
	const userInfo = getUserLocalStorage();
	let authToken = "";
	if (userInfo !== null) {
		if (userInfo.authToken !== undefined && userInfo.authToken !== "") {
			authToken = userInfo.authToken;
		}
	}
	return authToken;
};
