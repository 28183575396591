/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import "./Main.scss";
import { fetchMachine } from "./../logic/network/fetchMachine";
import Help from "../components/overlays/Help";
import { SectionLoader, SimpleLoader } from "../components/Loader";
import { Fragment } from "react";
import StoreCards from "../components/StoreCards";
import StoreDetail from "../components/StoreDetail";
import AgentDetail from "../components/AgentDetail";
const API_BASE = process.env.REACT_APP_API_URL;
// https://dev.snap2insight.com/api/Snap2Buy-4.0/service/S2B/support/users?customerCode=ITGDEM
const apiCreator = (fieldName, inputText, customerCode) => `${API_BASE}/support/search?${fieldName}=${inputText}&customerCode=${customerCode}`;
const basicApiCreator = (fieldName, inputText, customerCode) => `${API_BASE}/support/getBasicInfo?${fieldName}=${inputText}&customerCode=${customerCode}`;
const userListApiCreator = (customerCode) => `${API_BASE}/support/users?customerCode=${customerCode}`;

export const Main = ({ appError, validationError, dispatch }) => {
	let customersApi = `${API_BASE}/support/customers`;
	const [inputText, setInputText] = useState("");
	const [searchMode, setSearchMode] = useState(1);
	const [fieldName, setFieldName] = useState("storeId");
	const [api, setApi] = useState(`${API_BASE}/support/search`);
	const [basicInfoAPI, setBasicInfoAPI] = useState(`${API_BASE}/support/getBasicInfo`);
	const [userListApi, setUserListApi] = useState(`${API_BASE}/support/users?customerCode=ITGDEM`);
	const [customerCode, setCustomerCode] = useState("");
	const [apiData, setApiData] = useState({ basicInfo: null, fullInfo: null });
	const [loading, setLoading] = useState({ basicInfo: false, fullInfo: false, userList: false });
	const [error, setError] = useState({ basicInfo: null, fullInfo: null, userList: null });
	const [visitCount, setVisitCount] = useState("-");
	const [applyAgain, setApplyAgain] = useState(true);
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [userList, setUserList] = useState([]);
	const [userListStr, setUserListStr] = useState([]);
	const [liveSearchResult, setLiveSearchResult] = useState([]);
	const inputElement = useRef();

	useEffect(() => {
		fetchMachine.get(customersApi).then((json) => {
			setDropdownOptions(json.ResultSet.row);
			setCustomerCode(json.ResultSet.row[0].customerCode);
		});
		// if (process.env.NODE_ENV === "development") {
		// 	// let inp = "rohmhucq";
		// 	// let mode = 0;
		// 	// let field = "storeId";
		// 	let inp = "adam.schlesener@itgbrands.com";
		// 	// inp = "demo";
		// 	let mode = 1;
		// 	let field = "userId";
		// 	setSearchMode(mode);
		// 	setInputText(inp);
		// 	setFieldName(field);
		// 	setApi(apiCreator(field, inp, "ITGBND"));
		// 	fetchApiData(apiCreator(field, inp, "ITGBND"));
		// 	fetchBasicInfo(basicApiCreator(field, inp, "ITGBND"));
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		inputElement.current && inputElement.current.focus();
	}, [inputElement]);

	useEffect(() => {
		let newUserListApi = userListApiCreator(customerCode);
		setUserListApi(newUserListApi);
		setUserList([]);
		setError((s) => ({ ...s, userList: null }));
		setLoading((s) => ({ ...s, userList: true }));
		fetchMachine
			.get(newUserListApi)
			.then((json) => {
				setUserList(json.ResultSet.row);
				let str = json.ResultSet.row.map((e) =>
					Object.keys(e)
						.map((k) => e[k])
						.join(" ")
						.toLowerCase()
				);
				setUserListStr(str);
				// setUserListStr(json.ResultSet.row.map((e) => JSON.stringify(e).toLowerCase()));
				setApplyAgain(false);
				setLoading((s) => ({ ...s, userList: false }));
			})
			.catch((e) => setError((s) => ({ ...s, userList: e.message })));
	}, [customerCode]);

	const liveSearch = (query) => {
		if (!query || userListStr.length <= 0) {
			return [];
		}
		if (query === "*" || query === " ") {
			return userList;
		}
		let regex = new RegExp(`(.*)(${query})(.*)`, "i");
		return userList.filter((_, i) => userListStr[i].match(regex));
	};

	useEffect(() => {
		setApplyAgain(true);
		if (searchMode === 0) {
			setFieldName("storeId");
			setInputText("");
		}
		if (searchMode === 1) {
			setFieldName("userId");
			setInputText("");
		}
	}, [searchMode]);

	useEffect(() => {
		setApplyAgain(true);
		setApi(apiCreator(fieldName, inputText, customerCode));
		setBasicInfoAPI(basicApiCreator(fieldName, inputText, customerCode));
	}, [fieldName, inputText, customerCode]);

	const fetchApiData = (overwrideApi) => {
		setApiData((s) => ({ ...s, fullInfo: null }));
		setError((s) => ({ ...s, fullInfo: null }));
		setLoading((s) => ({ ...s, fullInfo: true }));

		fetchMachine
			.get(overwrideApi || api)
			.then((json) => {
				if (Object.keys(json.ResultSet.row[0]).length === 0) {
					setApiData((s) => ({ ...s, fullInfo: null }));
					setError((s) => ({ ...s, fullInfo: "No data returned.." }));
					setLoading((s) => ({ ...s, fullInfo: false }));
					return;
				}
				setApiData((s) => ({ ...s, fullInfo: json.ResultSet.row[0] }));

				setApplyAgain(false);
				setLoading((s) => ({ ...s, fullInfo: false }));
			})
			.catch((e) => setError((s) => ({ ...s, fullInfo: e.message })));
	};
	const fetchBasicInfo = (overwrideApi) => {
		setApiData((s) => ({ ...s, basicInfo: null }));
		setError((s) => ({ ...s, basicInfo: null }));
		setLoading((s) => ({ ...s, basicInfo: true }));

		fetchMachine
			.get(overwrideApi || basicInfoAPI)
			.then((json) => {
				if (Object.keys(json.ResultSet.row[0]).length === 0) {
					setApiData((s) => ({ ...s, basicInfo: null }));
					setError((s) => ({ ...s, basicInfo: "No data returned.." }));
					setLoading((s) => ({ ...s, basicInfo: false }));
					return;
				}
				setApiData((s) => ({ ...s, basicInfo: json.ResultSet.row[0] }));

				setApplyAgain(false);
				setLoading((s) => ({ ...s, basicInfo: false }));
			})
			.catch((e) => setError((s) => ({ ...s, basicInfo: e.message })));

		// const temp = {
		// 	userId: "adam.schlesener@itgbrands.com",
		// 	firstName: "Adam",
		// 	lastName: "Schlesener",
		// 	phoneNumber: "619-346-2957",
		// 	lastLoginDate: "2020-10-05 00:39:50",
		// 	lastAccessedDate: "2021-08-04 19:32:46",
		// 	mappedTerritory: "Area 3 - Region 15 - Division 151",
		// 	mappedUser: "adam.schlesener@itgbrands.com",
		// 	role: "agent",
		// };
		// setTimeout(() => {
		// 	setApiData((s) => ({ ...s, basicInfo: temp }));
		// 	setApplyAgain(false);
		// 	setLoading((s) => ({ ...s, basicInfo: false }));
		// }, 2000);
	};
	const handleSubmit = (e) => {
		e && e.preventDefault();
		fetchApiData();
		fetchBasicInfo();
	};

	let storeCards = useMemo(() => {
		if (apiData?.fullInfo?.visits) {
			let actual_data = apiData?.fullInfo.visits;
			setVisitCount(actual_data.length);
			return actual_data.map((e, i) => <StoreCards key={i} data={e} />);
		} else {
			return [];
		}
	}, [apiData?.fullInfo]);

	let detailCard = useMemo(() => {
		if (apiData?.basicInfo) {
			// by store
			if (searchMode === 0) {
				// let { storeId, street, city, state } = apiData?.basicInfo;
				// let data = { storeId, street, city, state };
				let data = { ...apiData?.basicInfo };
				return <StoreDetail data={data} />;
			}
			// by agent
			if (searchMode === 1) {
				// let { userId, firstName, lastName, lastLoginDate, lastAccessedDate, phoneNumber } = apiData?.basicInfo;
				// let data = { userId, firstName, lastName, lastLoginDate, lastAccessedDate, phoneNumber };
				let data = { ...apiData?.basicInfo };
				return <AgentDetail data={data} customerCode={customerCode} />;
			}
		} else {
			return [];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiData?.basicInfo]);

	const searchResult = (firstName, lastName, email, input, key) => {
		let regex = new RegExp(`(.*)(${input})(.*)`, "i");
		let fullname = firstName + " " + lastName;
		let nameMatch = fullname?.match(regex);
		let emailMatch = email?.match(regex);
		return (
			<span
				key={key}
				className='search-result py-1 px-2'
				onClick={(_) => {
					setInputText(email);
					fetchApiData(apiCreator(fieldName, email, customerCode));
					fetchBasicInfo(basicApiCreator(fieldName, email, customerCode));
				}}>
				<Fragment>
					{nameMatch ? (
						<Fragment>
							<span className='d-inline'>{nameMatch[1] ?? " "}</span>
							<span className='d-inline highlight'>{nameMatch[2] ?? ""}</span>
							<span className='d-inline'>{nameMatch[3] ?? " "}</span>
						</Fragment>
					) : (
						<span className='d-inline'>{fullname}</span>
					)}
					<span className='d-inline small text-muted px-2'>
						{emailMatch ? (
							<Fragment>
								(<span className='d-inline'>{emailMatch[1] ?? " "}</span>
								<span className='d-inline highlight'>{emailMatch[2] ?? ""}</span>
								<span className='d-inline'>{emailMatch[3] ?? " "}</span>)
							</Fragment>
						) : (
							<span className='d-inline'>({email})</span>
						)}
					</span>
				</Fragment>
			</span>
		);
	};

	return (
		<Fragment>
			<div id='main' className=''>
				{/* first row with options */}
				<div className='row menu mb-1'>
					<form className='form-inline menu-form'>
						<div className='form-group mb-2 mx-2 center'>
							<label htmlFor='customer'>Customer</label>
							<select
								className='form-control mr-1 p-0 px-2 py-1'
								name='customer'
								id='customer'
								onChange={(e) => {
									setCustomerCode(e.target.value);
									inputElement.current.focus();
								}}
								value={customerCode}>
								{dropdownOptions.map((e, i) => {
									return (
										<option key={i} value={e.customerCode}>
											{e.customerName}
										</option>
									);
								})}
							</select>
						</div>

						<div className='form-group mb-2 mx-2 center search-toggle-option'>
							<label htmlFor='searchby'>Search by</label>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									id='option1'
									onChange={(e) => {
										setSearchMode(0);
										inputElement.current.focus();
									}}
									checked={searchMode === 0}
								/>
								<label className='form-check-label no-after mr-1' htmlFor='option1'>
									Store number
								</label>
								<span className={`live-search-indiacator d-inline mr-3 mb-1 no-help-icon offline`} data-help='Live Search offline'></span>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									id='option2'
									onChange={(e) => {
										setSearchMode(1);
										inputElement.current.focus();
									}}
									checked={searchMode === 1}
								/>
								<label className='form-check-label no-after mr-1' htmlFor='option2'>
									E-mail/Name
								</label>
								<span className={`live-search-indiacator d-inline mr-1 mb-1 no-help-icon ${userList.length > 0 ? "live" : "offline"}`} data-help={`Live Search ${userList.length > 0 ? "Online" : "Offline"}`}></span>
							</div>
						</div>

						<div className='form-group mb-2 mx-2 center p-relative'>
							<input
								ref={inputElement}
								type='text'
								id='inputText'
								className='form-control py-0 w-300'
								value={inputText}
								placeholder={searchMode === 0 ? "807912..." : "john@doe.com..."}
								onChange={(e) => {
									if (searchMode === 1) {
										let res = liveSearch(e.target.value);
										setLiveSearchResult(res);
									}
									setInputText(e.target.value);
								}}
								onFocus={(e) => {
									if (searchMode === 1) {
										let res = liveSearch(e.target.value);
										setLiveSearchResult(res);
									}
								}}
								onBlur={(_) =>
									setTimeout(() => {
										setLiveSearchResult([]);
									}, 500)
								}
							/>
							{searchMode === 1 && liveSearchResult.length > 0 && (
								<div className='live-search-result'>
									{liveSearchResult.map((e, i) => {
										return searchResult(e.firstName, e.lastName, e.email, inputText, i);
									})}
								</div>
							)}
						</div>

						<div className='form-group mb-2 mx-2 center'>
							<button className={`btn ${applyAgain ? "btn-danger" : "btn-primary"} btn-sm`} onClick={handleSubmit}>
								Submit
							</button>
						</div>
					</form>
				</div>
				{/* {error && <p className='h5 text-center my-2 text-danger w-100'>{JSON.stringify(error, null, 2)}</p>} */}
				{/* {error && <p className='h5 text-center my-2 text-danger w-100'>{JSON.stringify(error, null, 2)}</p>} */}
				{/* {loading && <p className='h5 text-center my-2 text-primary w-100'>{JSON.stringify(loading, null, 2)}</p>} */}
				{/* {loading && <SectionLoader msg='Loading....' />} */}
				{apiData && (
					<div className='row'>
						<div className='col-8'>
							{error.fullInfo && <p className='h5 text-center my-2 text-danger w-100'>{error.fullInfo}</p>}
							{loading.fullInfo && <SimpleLoader msg='Loading....' />}
							{apiData.fullInfo && !error.fullInfo && !loading.fullInfo && (
								<>
									<div className='col-12'>{storeCards.length > 0 && <h6 className='text-muted text-right pr-3'>Last {visitCount} visits</h6>}</div>
									<div className='col-12'>{storeCards.length > 0 ? storeCards : <p className='h5 text-center my-2 text-danger w-100'>No Data</p>}</div>
								</>
							)}
						</div>
						<div className='col-4'>
							{error.basicInfo && <p className='h5 text-center my-2 text-danger w-100'>{error.basicInfo}</p>}
							{loading.basicInfo && <SimpleLoader msg='Loading....' />}
							{!error.basicInfo && !loading.basicInfo && apiData.basicInfo && detailCard}
						</div>
					</div>
				)}
			</div>
			<Help />
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		appError: state.app.error,
		validationError: state.app.validationError,
	};
};

export default connect(mapStateToProps, null)(Main);
