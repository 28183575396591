import React from "react";
import { connect } from "react-redux";
import "./Help.scss";
export const Help = ({ help }) => {
	if (!help) {
		return "";
	}
	let shortcuts = [
		["Help text", "shortcut key"],
		["Toggle this help ❔", "H"],
	];
	const helpCreator = (e, i) => (
		<tr key={i}>
			<td>{e[0]}</td>
			<td>{e[1]}</td>
		</tr>
	);
	return (
		<div className='help-modal'>
			<div className='backdrop'></div>
			<div className='main-help text-white z-10'>
				<h1 className='text-center'>Help?</h1>
				<table className='table table-striped table-dark'>
					<thead>
						<tr>
							<th>Action</th>
							<th>Shortcut</th>
						</tr>
					</thead>
					<tbody>{shortcuts.map((e, i) => helpCreator(e, i))}</tbody>
				</table>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ help: state.app.help });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
