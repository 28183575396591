import React from "react";
import "./StoreCards.scss";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import {customRelativeDateTime, customDayFormatter } from "../logic/util";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
const StoreCards = ({ data }) => {
	let { projects, city, street, visitDateId, state, storeId, firstName, lastName } = data;

	let {relativeTime ,readableTime} = customRelativeDateTime(visitDateId)
	let projectsItems = projects.map((e, i) => {
		let date = e.visitDateTime && new Date(e.visitDateTime + " UTC");
		let relativeTime = date && timeAgo.format(date);
		let readableTime = customDayFormatter(date).readableTime
		return (
			<div className='flex-space-between' key={i}>
				<span className=''>{e.projectName}</span>
				<span className=''>
					{e.receivedImageCount} of {e.expectedImageCount} photos &nbsp;
					<span className='small text-muted no-help-icon' data-help={readableTime ? readableTime : date && date.toDateString()}>
						( {relativeTime} )
					</span>
				</span>
			</div>
		);
	});

	return (
		<div className='card mb-3'>
			<div className='card-header px-2 py-2 pt-1'>
				<div className='flex-space-between'>
					<span className='font-weight-bold h5'>{storeId}</span>
					<span className='font-weight-bold h5 center'>
						{firstName} {lastName}
					</span>
				</div>
				<div className='flex-space-between'>
					<span className='h6 text-muted'>
						{street}, {city}, {state}
					</span>
					<span className='small text-muted text-right'>
						{relativeTime} {readableTime && `, ${readableTime}`}
					</span>
				</div>
			</div>
			<div className='card-body px-2 pt-3'>
				<div className='row w-100 m-0'>
					<div className='col-3'></div>
				</div>
				{projectsItems}
			</div>
		</div>
	);
};
export default StoreCards;
